import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import UsageType from '../../components/UsageType';
import { HttpClient } from '../../helpers/httpClient';
import { Horse } from '../../types/Horses';
import { LessonModifications } from '../../types/Lessons';
import { Person } from '../../types/People';
import dayjs from 'dayjs';
import { Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function AddUsage() {
  const location = useLocation();
  const [type, setType] = useState('');
  const [riders, setRiders] = useState<Person[]>([]);
  const [horses, setHorses] = useState<Horse[]>([]);
  const [modifications, setModifications] = useState<LessonModifications[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState<dayjs.Dayjs>(
    location?.state?.date ? location?.state?.date : dayjs()
  );

  async function getRiders(setRiders: Function) {
    try {
      const result = await HttpClient.get('/riders');
      setRiders(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getHorses(setHorses: Function) {
    try {
      const result = await HttpClient.get('/horses');
      console.log(result);
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getModifications(setModifications: Function, date: dayjs.Dayjs) {
    console.log(date);
    try {
      const result = await HttpClient.get(
        `/riders/absences?date=${encodeURIComponent(date.format('YYYY-MM-DD'))}`
      );
      setModifications(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getRiders(setRiders);
    getHorses(setHorses);
    // getModifications(setModifications, date);
  }, [date]);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Add Usage
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding usage' errorMessage={errorMessage} />
      <form id='usageForm'>
        <Grid container marginBottom='1em'>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Usage Date'
                value={date}
                onChange={(e: any) => setDate(e)}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container marginBottom='1em'>
          <Grid item xs={12}>
            <TextField
              label='Type'
              select
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setType(e.target.value);
              }}
              value={type}
              name='type'
              fullWidth
            >
              <MenuItem value='G'>Horsemanship or LTR (G)</MenuItem>
              <MenuItem value='FL'>Free Lunge (FL)</MenuItem>
              <MenuItem value='LB'>Little Bits (LB)</MenuItem>
              <MenuItem value='UM'>Unmounted (UM)</MenuItem>
              <MenuItem value='HR'>Hack Ride (HR)</MenuItem>
              <MenuItem value='BP'>Birthday Party (BP)</MenuItem>
              <MenuItem value='P'>Private/Semi-Private (P/SP)</MenuItem>
              <MenuItem value='SC'>Summer Camp (SC)</MenuItem>
              <MenuItem value='S'>Schooling (S)</MenuItem>
              <MenuItem value='LR'>Lease Ride (LR)</MenuItem>
              <MenuItem value='BR'>Boarder Ride (BR)</MenuItem>
              <MenuItem value='L'>Lunging (L)</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <UsageType
          type={type}
          modifications={modifications}
          riders={riders}
          horses={horses}
          setErrorMessage={setErrorMessage}
          date={date}
        />
      </form>
    </Container>
  );
}

export default AddUsage;
