import { ChangeEvent, useState } from 'react';
import { Horse, HorseFarrier } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import LimitedTextArea from '../LimitedTextArea';
import BasicModal from './BasicModal';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import HorseForm from '../Horses/HorseForm';

function EditHorseModal(props: {
  horse: Horse;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [horse, setHorse] = useState<Horse>(props.horse);

  async function handleSubmit() {
    setErrorMessage('');

    const form: any = document.getElementById('horseForm');

    try {
      if (form && form.checkValidity()) {
        if (horse.notes && horse.notes.length > 1000) {
          setErrorMessage('Notes section is too long. Please check and reduce the size.');
          return;
        }

        await HttpClient.put(`/horses/${props.horse.id}`, { ...horse });

        props.handleConfirm();
        props.handleClose();
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Update Horse'
      submitButton={{
        color: 'success',
        text: 'Update',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error updating horse' errorMessage={errorMessage} />
      <form id='horseForm'>
        <HorseForm horse={horse} setHorse={setHorse} />
      </form>
    </BasicModal>
  );
}

export default EditHorseModal;
