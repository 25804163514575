import { ChangeEvent, useState } from 'react';
import { AffectedPart, HorseLameness } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import LimitedTextArea from '../LimitedTextArea';
import BasicModal from './BasicModal';
import { Box, Button, MenuItem, TextField } from '@mui/material';

function UpdateLamenessModal(props: {
  horse: string;
  horseName: string;
  lameness: HorseLameness;
  show: boolean;
  handleClose: () => void;
  handleConfirm: Function;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [lameness, setLameness] = useState<HorseLameness>(props.lameness);

  async function handleSubmit() {
    setErrorMessage('');

    const form: any = document.getElementById('lamenessForm');

    try {
      if (form && form.checkValidity()) {
        if (lameness.notes && lameness.notes.length > 1000) {
          setErrorMessage('Comments section is too long. Please check and reduce the size.');
          return;
        }

        const result = await HttpClient.put(`/horses/${props.horse}/lameness/${lameness.id}`, {
          ...lameness,
          horseName: props.horseName,
          endDate: lameness.endDate === 'Ongoing' ? undefined : lameness.endDate,
          checkDate: lameness.endDate === 'Ongoing' ? lameness.checkDate : undefined,
        });

        props.handleConfirm();
        props.handleClose();
      } else {
        setErrorMessage(`Please enter if the horse is still lame and a recheck or end date.`);
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  return (
    <BasicModal
      onClose={props.handleClose}
      open={props.show}
      title='Update Lameness'
      submitButton={{
        color: 'success',
        text: 'Update',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      <ErrorBanner errorHeading='Error adding lameness' errorMessage={errorMessage} />
      <form id='lamenessForm'>
        <Box marginBottom={'1em'}>
          <TextField
            label='Affected Part'
            select
            required
            aria-label='Affected Part'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLameness((prevState) => ({
                ...prevState,
                affectedPart: e.target.value as AffectedPart,
              }));
            }}
            value={lameness.affectedPart}
            name='affectedPart'
            error={!lameness.affectedPart}
            fullWidth
          >
            <MenuItem value={AffectedPart.FrontLeft}>Front Left</MenuItem>
            <MenuItem value={AffectedPart.FrontRight}>Front Right</MenuItem>
            <MenuItem value={AffectedPart.BackLeft}>Back Left</MenuItem>
            <MenuItem value={AffectedPart.BackRight}>Back Right</MenuItem>
            <MenuItem value={AffectedPart.MultipleLegs}>Multiple Legs</MenuItem>
            <MenuItem value={AffectedPart.Other}>Other</MenuItem>
            <MenuItem value={AffectedPart.Unknown}>Unknown</MenuItem>
          </TextField>
        </Box>
        <Box marginBottom={'1em'}>
          <TextField
            label='Recheck Date'
            required
            type='date'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLameness((prevState) => ({
                ...prevState,
                checkDate: e.target.value,
              }));
            }}
            value={lameness.checkDate}
            name='checkDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box marginBottom={'1em'}>
          <TextField
            fullWidth
            label={'Date Horse Stopped Being Lame'}
            type='date'
            onChange={(e) => {
              setLameness((prevState) => ({
                ...prevState,
                endDate: e.target.value,
              }));
            }}
            value={lameness.endDate}
            name='date'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box marginBottom={'1em'}>
          <LimitedTextArea
            maxChars={1000}
            label='Comments'
            data={lameness.notes ? lameness.notes : ''}
            setData={(e: ChangeEvent<HTMLInputElement>) => {
              setLameness((prevState) => ({
                ...prevState,
                notes: e.target.value,
              }));
            }}
          />
        </Box>
      </form>
    </BasicModal>
  );
}

export default UpdateLamenessModal;
