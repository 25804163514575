import { Box, Button, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import LimitedTextArea from '../LimitedTextArea';
import HorseHeader from './HorseHeader';
import { Horse } from '../../types/Horses';
import ErrorBanner from '../ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';

function HorseNote(props: { horse: Horse }) {
  const [showError, setShowError] = useState(false);
  const [note, setNote] = useState(props.horse.notes);
  const [editMode, setEditMode] = useState(false);

  return (
    <Box>
      <HorseHeader
        title='Notes'
        onClick={() => setEditMode(true)}
        edit={true}
        buttonDisabled={editMode}
      />
      {note || editMode ? (
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: '16px',
            padding: '16px',
            overflow: 'hidden',
            marginBottom: '1em',
          }}
        >
          {editMode ? (
            <>
              {showError ? (
                <ErrorBanner
                  errorHeading='Error updating note'
                  errorMessage='Please try again. If the issue persists please contact Rebecca.'
                />
              ) : null}
              <LimitedTextArea
                maxChars={1000}
                label='Note'
                data={note ?? ''}
                setData={(event: ChangeEvent<HTMLInputElement>) => {
                  setNote(event.target.value);
                }}
              />
              <Button
                variant='contained'
                color='success'
                fullWidth
                onClick={async () => {
                  setShowError(false);
                  try {
                    await HttpClient.put(`horses/${props.horse.id}`, {
                      ...props.horse,
                      notes: note,
                    });
                    setEditMode(false);
                  } catch {
                    setShowError(true);
                  }
                }}
              >
                Update
              </Button>
            </>
          ) : (
            <Box>
              <Typography>{note}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Typography>N/A</Typography>
      )}
    </Box>
  );
}

export default HorseNote;
