import { Box, Grid, MenuItem, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import LimitedTextArea from '../LimitedTextArea';
import { Horse, horseType, owners } from '../../types/Horses';
import MultiSelectDropdown from '../MultipleSelect';

function HorseForm(props: { horse: Horse; setHorse: (horse: Horse) => void; showNotes?: boolean }) {
  const { horse, setHorse, showNotes } = props;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Name'
            required
            placeholder='Name'
            name='barnName'
            onChange={(e) => {
              setHorse({
                ...horse,
                barnName: e.target.value,
              });
            }}
            value={horse.barnName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Registered Name'
            placeholder='Registered Name'
            name='showName'
            onChange={(e) => {
              setHorse({
                ...horse,
                showName: e.target.value,
              });
            }}
            value={horse.showName}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop='1em'>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Owner'
            select
            required
            aria-label='Owner'
            onChange={(e) => {
              setHorse({
                ...horse,
                owner: e.target.value,
              });
            }}
            value={horse.owner}
            name='owner'
            fullWidth
          >
            {Object.keys(owners).map((owner: string, index: number) => {
              return (
                <MenuItem key={index} value={owner}>
                  {owners[owner]}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label='Type'
            select
            required
            aria-label='Type'
            onChange={(e) => {
              setHorse({
                ...horse,
                type: Number(e.target.value),
              });
            }}
            value={horse.type}
            name='type'
            fullWidth
          >
            {Object.keys(horseType).map((type: string, index: number) => {
              return (
                <MenuItem key={index} value={type}>
                  {horseType[Number(type)]}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop='1em'>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Birth Date'
            required
            type='date'
            onChange={(e) => {
              setHorse({
                ...horse,
                birthDate: e.target.value,
              });
            }}
            value={horse.birthDate}
            name='birthDate'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Arrival Date'
            type='date'
            onChange={(e) => {
              setHorse({
                ...horse,
                startDate: e.target.value,
              });
            }}
            value={horse.startDate}
            name='startDate'
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      {horse.type !== 2 ? (
        <Grid container spacing={1} marginTop='1em'>
          <Grid item xs={12}>
            <MultiSelectDropdown
              options={[
                { display: 'Sunday', value: 'Sunday' },
                { display: 'Monday', value: 'Monday' },
                { display: 'Tuesday', value: 'Tuesday' },
                { display: 'Wednesday', value: 'Wednesday' },
                { display: 'Thursday', value: 'Thursday' },
                { display: 'Friday', value: 'Friday' },
                { display: 'Saturday - AM', value: 'Saturday - AM' },
                { display: 'Saturday - PM', value: 'Saturday - PM' },
              ]}
              label='Days Off'
              selectedItems={horse.daysOff}
              setSelectedItems={(event) => {
                setHorse({
                  ...horse,
                  daysOff:
                    typeof event.target.value === 'string'
                      ? [event.target.value]
                      : event.target.value,
                });
              }}
              required={true}
            />
          </Grid>
        </Grid>
      ) : null}
      {showNotes ? (
        <Box marginTop={'1em'}>
          <LimitedTextArea
            maxChars={1000}
            label='Notes'
            data={horse.notes ?? ''}
            setData={(event: ChangeEvent<HTMLInputElement>) => {
              setHorse({
                ...horse,
                notes: event.target.value,
              });
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default HorseForm;
