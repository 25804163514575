import { Box, TextField, TextareaAutosize } from '@mui/material';
import { ChangeEventHandler } from 'react';

function LimitedTextArea(props: {
  maxChars: number;
  label: string;
  data: string;
  setData: ChangeEventHandler;
}) {
  const maxChars = props.maxChars;

  return (
    <>
      <Box>
        <TextField
          label={props.label}
          multiline
          minRows={3}
          onChange={props.setData}
          value={props.data}
          style={{ width: '100%' }}
        />
      </Box>
      <Box>
        <div style={{ textAlign: 'right', color: props.data.length > maxChars ? 'red' : 'grey' }}>
          {props.data.length}/{maxChars}
        </div>
      </Box>
    </>
  );
}

export default LimitedTextArea;
