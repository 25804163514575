import {
  Button,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lesson } from '../../types/Lessons';
import { HttpClient } from '../../helpers/httpClient';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { displayRiderLevel, formatTime } from '../../helpers/utils';
import DropDownWithButton from '../../components/DropDownWithButton';
import ErrorBanner from '../../components/ErrorBanner';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeletionModal from '../../components/Modals/ConfirmDeletionModal';
import AddLessonModal from '../../components/Riders/AddLessonModal';
import EditLesson from './EditLesson';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Day } from '../../types/Day';

function Lessons() {
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const navigate = useNavigate();
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [currentLesson, setCurrentLesson] = useState<Lesson | undefined>();
  const [mode, setMode] = useState<'view' | 'edit' | 'delete'>('view');
  const [radioValue, setRadioValue] = useState<'all' | 'current' | null>('current');

  async function handleDelete() {
    setButtonsDisabled(true);
    setErrorMessage('');
    if (currentLesson) {
      try {
        const result = await HttpClient.delete(`/lessons/${currentLesson.id}`);
        console.log(result);
        setMode('view');
        await getLessons();
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    }

    setButtonsDisabled(false);
  }

  function formatTimeForEdit(time: string) {
    let [timePart, amPm] = time.split(' ');
    let [hour, min] = timePart.split(':');
    if (amPm.includes('PM') && hour != '12') {
      hour = `${Number(hour) + 12}`;
    } else if (amPm.includes('AM') && hour === '12') {
      hour = '0';
    }

    return `${hour}:${min}`;
  }

  const getLessons = async () => {
    let queryParams = {};
    if (radioValue === 'all') {
      queryParams = { amount: 'all' };
    }

    try {
      const result: Lesson[] = await HttpClient.get('/lessons', {}, queryParams);
      setLessons(
        result.map((lesson) => {
          return {
            ...lesson,
            lessonTime: lesson.lessonTime ? formatTime(lesson.lessonTime) : '',
          };
        })
      );
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  };

  useEffect(() => {
    getLessons();
  }, [radioValue]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            id={`edit-${params.row.id}`}
            onClick={() => {
              setMode('edit');
              console.log(params.row);
              setCurrentLesson({
                ...params.row,
                lessonTime: formatTimeForEdit(params.row.lessonTime),
              });
            }}
            style={{ marginRight: '0.5em' }}
            disabled={buttonsDisabled}
          >
            <EditIcon style={{ width: '20', paddingBottom: '3.5px' }} />
          </Button>
          <Button
            variant='contained'
            color='error'
            id={`delete-${params.row.id}`}
            onClick={() => {
              setMode('delete');
              setCurrentLesson(params.row);
            }}
            disabled={buttonsDisabled}
          >
            <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
          </Button>
        </strong>
      ),
    },
    { field: 'startDate', headerName: 'Start Date', flex: 1, minWidth: 130 },
    { field: 'endDate', headerName: 'End Date', flex: 1, minWidth: 130 },
    { field: 'program', headerName: 'Program', flex: 1, minWidth: 130 },
    {
      field: 'activeDays',
      headerName: 'Lesson Days',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => <>{params.row.activeDays ? params.row.activeDays.join(', ') : ''}</>,
    },
    { field: 'lessonTime', headerName: 'Lesson Time', flex: 1, minWidth: 130 },
    {
      field: 'level',
      headerName: 'Level',
      flex: 1,
      minWidth: 130,
      type: 'number',
      renderCell: (params) => <>{displayRiderLevel(params.row.level)}</>,
    },
    { field: 'coach', headerName: 'Coach', flex: 1, minWidth: 130 },
  ];

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Lessons
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        paddingBottom='1em'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={4}>
          <ToggleButtonGroup
            value={radioValue}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newFilter: 'all' | 'current' | null
            ) => {
              setRadioValue(newFilter);
            }}
            aria-label='report type'
            fullWidth
            color='success'
          >
            <ToggleButton value='all'>All</ToggleButton>
            <ToggleButton value='current'>Current</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            select
            label='Filter by day'
            value={searchField}
            onChange={(event) => {
              setSearchField(event.target.value);
            }}
            variant='outlined'
            fullWidth
          >
            {[
              {
                display: 'All',
                value: '',
              },
              {
                display: 'Sunday',
                value: 'Sunday',
              },
              {
                display: 'Monday',
                value: 'Monday',
              },

              {
                display: 'Tuesday',
                value: 'Tuesday',
              },
              {
                display: 'Wednesday',
                value: 'Wednesday',
              },
              {
                display: 'Thursday',
                value: 'Thursday',
              },
              {
                display: 'Friday',
                value: 'Friday',
              },
              {
                display: 'Saturday',
                value: 'Saturday',
              },
            ].map((option, index) => {
              return (
                <MenuItem value={option.value} key={index}>
                  {option.display}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            onClick={() => {
              navigate('/lessons/new');
            }}
            variant='contained'
            color='success'
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add New
          </Button>
        </Grid>
      </Grid>
      <ErrorBanner errorHeading='Error getting lessons' errorMessage={errorMessage} />
      <ConfirmDeletionModal
        show={mode === 'delete'}
        handleClose={() => setMode('view')}
        handleConfirm={handleDelete}
      />
      {mode === 'edit' ? (
        <EditLesson
          lesson={currentLesson}
          onSubmit={async () => {
            await getLessons();
            setCurrentLesson(undefined);
          }}
          onClose={() => {
            setMode('view');
          }}
          show={mode === 'edit'}
        />
      ) : (
        <div style={{ height: '78vh', width: '100%' }}>
          <DataGrid
            rows={lessons.filter((lesson) => {
              if (searchField) {
                return lesson.activeDays.includes(searchField as Day);
              }

              return true;
            })}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </div>
      )}
    </Container>
  );
}

export default Lessons;
