import { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import DayViewColumn from './DayViewColumn';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Horse } from '../../types/Horses';

export type BoardEntry = {
  boardId: string;
  personId: string;

  firstName: string;
  lastName: string;

  horseId: string;
  horseName: string;
  horseChangedTime: string;

  horseIn: boolean;
  feedAfterClass: boolean;
  notes: string;

  entryModifications: string;
  riderAbsent: boolean;

  saddle: {
    saddle: string;
    pads: string;
    additions: string;
  };
};

export type LessonOnBoard = {
  lessonId: number;
  lessonTime: string;
  lessonNotes: string;
  lessonLevel?: number;
  entries: BoardEntry[];
  program: string;
  notes: string;
};

export type BoardDay = LessonOnBoard[];

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== '' && str2.trim() !== '') {
    return str1 + ', ' + str2;
  } else if (str1) {
    return str1;
  }

  return str2;
}

function DayView() {
  const [errorMessage, setErrorMessage] = useState('');
  const [day, setDay] = useState<BoardDay>();
  const [horses, setHorses] = useState<Horse[]>([]);

  const [isInstructor, setIsInstructor] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  function stupidDateGetter(date: string | undefined) {
    if (!date || date.split('-').length != 3) {
      console.log('Invalid date param');
      navigate(`/usage/${dayjs().format('YYYY-MM-DD')}`);
      return dayjs();
    }
    return dayjs(date);
  }
  const [date, setDate] = useState<dayjs.Dayjs | null>(stupidDateGetter(params.date));

  async function getBoard() {
    setErrorMessage('');
    if (!date) {
      return;
    }

    try {
      const result: BoardDay = await HttpClient.get(
        `/board/day/${dayString[date.day()]}`,
        {},
        { date: date.format('YYYY-MM-DD') }
      );
      setDay(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getGroup() {
    const session = await Amplify.Auth.currentSession();
    const groups: string[] = session.getIdToken()?.payload?.['cognito:groups'] ?? [];

    setIsInstructor(groups.find((value: string) => value === 'Instructors') !== undefined);
  }

  async function getHorses() {
    try {
      const result: Horse[] = await HttpClient.get('/horses');
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  const currentlyLameHorses = horses.filter((horse: Horse) => {
    return horse.currentLameness && horse.currentLameness > 0;
  });

  useEffect(() => {
    getBoard();
    getGroup();
    getHorses();
  }, [date]);

  return (
    <Box margin={'1em'}>
      <Grid
        container
        spacing={2}
        paddingBottom='1em'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={isInstructor ? 9 : 12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(value, context) => {
                setDate(value);
                navigate(`/board/${value?.format('YYYY-MM-DD')}`);
              }}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        {isInstructor ? (
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              onClick={() =>
                navigate(
                  {
                    pathname: `/board/${date?.format('YYYY-MM-DD')}/change`,
                  },
                  {
                    state: {
                      date,
                    },
                  }
                )
              }
              variant='contained'
              color='success'
            >
              Edit Board
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ErrorBanner errorHeading='Error getting board' errorMessage={errorMessage} />
      <Typography style={{ fontWeight: 'bold' }}>Currently lame horses:</Typography>
      <Grid container marginBottom='1em'>
        <Grid item>
          {currentlyLameHorses && currentlyLameHorses.length > 0
            ? currentlyLameHorses
                .map((horse: Horse) => {
                  return horse.barnName;
                })
                .join(', ')
            : 'None'}
        </Grid>
      </Grid>
      <DayViewColumn lessons={day} />
    </Box>
  );
}

export default DayView;
