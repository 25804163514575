import React, { useState } from 'react';
import {
  Pagination,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { HorseLameness } from '../../types/Horses';

interface PaginatedListProps {
  data: HorseLameness[];
  onUpdateClick: (lameness: HorseLameness) => void;
}

const LamenessList: React.FC<PaginatedListProps> = ({ data, onUpdateClick }) => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current page's items
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Handle page change from MUI pagination component
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      {/* Render the current items in a list */}
      {currentItems.map((item) => (
        <Box
          key={item.id}
          sx={{
            border: '1px solid #ccc',
            borderRadius: '16px',
            padding: '16px',
            overflow: 'hidden',
            marginBottom: '1em',
          }}
        >
          <Grid container spacing={2}>
            {/* Large Column (left) */}
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: '#f4f4f4',
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >
                <Box>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography fontWeight={'bold'}>Start Date</Typography>
                      <Typography>{item.startDate}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography fontWeight={'bold'}>End Date</Typography>
                      <Typography>{item.endDate ? item.endDate : 'Ongoing lameness'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography fontWeight={'bold'}>Recheck Date</Typography>
                      <Typography>{item.checkDate ? item.checkDate : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography fontWeight={'bold'}>Affected Limb</Typography>
                      <Typography>{item.affectedPart}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container marginTop='1em' columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography fontWeight={'bold'}>Notes</Typography>
                      <Typography style={{ whiteSpace: 'pre-line' }}>{item.notes}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container marginTop={'1em'}>
            <Button
              variant='contained'
              color='success'
              fullWidth
              onClick={() => {
                onUpdateClick(item);
              }}
            >
              Update
            </Button>
          </Grid>
        </Box>
      ))}

      {/* MUI Pagination Component */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center', // Horizontally centers the Pagination
          alignItems: 'center', // Vertically centers the Pagination (if needed)
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color='standard'
          variant='outlined'
          shape='rounded'
          size='large'
        />
      </Box>
    </Box>
  );
};

export default LamenessList;
