import { Button, Grid, Typography } from '@mui/material';

import AddSharpIcon from '@mui/icons-material/AddSharp';
import EditIcon from '@mui/icons-material/Edit';

function HorseHeader(props: {
  title: string;
  onClick: () => void;
  edit?: boolean;
  buttonDisabled?: boolean;
}) {
  return (
    <Grid
      container
      borderBottom='1px solid #ccc'
      paddingBottom={'0.5em'}
      marginTop={'1em'}
      marginBottom={'1em'}
    >
      <Grid item xs={10}>
        <Typography variant='h6'>{props.title}</Typography>
      </Grid>
      <Grid item xs={2} container justifyContent={'flex-end'}>
        <Button
          variant='contained'
          color='success'
          disabled={props.buttonDisabled}
          onClick={props.onClick}
          style={{ marginRight: '0.5em' }}
        >
          {props.edit ? <EditIcon /> : <AddSharpIcon />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default HorseHeader;
