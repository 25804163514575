import { useEffect, useState } from 'react';
import { Horse } from '../../types/Horses';
import { useNavigate } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import { Container, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import HorseCards from '../../components/Horses/HorseCards';
import SearchWithButtons from '../../components/SearchWithButtons';
import { HorseAPI } from '../../helpers/requesters/horseAPI';
import SearchWithButtonsAndToggle from '../../components/SearchWithButtonsAndToggle';

function Horses() {
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [horses, setHorses] = useState<Horse[]>([]);
  const [radioValue, setRadioValue] = useState<string | null>('all');

  useEffect(() => {
    HorseAPI.getHorses(setHorses, setErrorMessage, true);
  }, []);

  let filteredHorses = horses.filter((horse) => {
    return (
      horse.barnName.toLowerCase().includes(searchField.toLowerCase()) ||
      (horse.showName ?? '').toLowerCase().includes(searchField.toLowerCase())
    );
  });

  if (radioValue == 'lesson') {
    filteredHorses = filteredHorses.filter((horse) => horse.type != 2);
  }

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Horses
        </Typography>
      </Grid>
      <Grid container>
        <SearchWithButtonsAndToggle
          label='Search horses'
          searchValue={searchField}
          setSearchValue={setSearchField}
          dropdownOptions={[
            { location: '/horses/new', display: 'Add Horse' },
            { location: '/horses/farrier/new', display: 'Add Farrier Appointment' },
          ]}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          radioOptions={[
            { display: 'All', value: 'all' },
            { display: 'Lesson', value: 'lesson' },
          ]}
        />
      </Grid>
      <ErrorBanner errorHeading='Error getting horses' errorMessage={errorMessage} />
      <HorseCards filteredHorses={filteredHorses} />
    </Container>
  );
}

export default Horses;
