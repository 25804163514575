import HorseCard from './HorseCard';
import { Horse } from '../../types/Horses';
import { Grid } from '@mui/material';

function HorseCards(props: { filteredHorses: Horse[] }) {
  return (
    <Grid container spacing={2}>
      {props.filteredHorses.map((horse, index) => (
        <Grid key={horse.id} item xs={12} sm={6} md={4}>
          <HorseCard horse={horse} />
        </Grid>
      ))}
    </Grid>
  );
}

export default HorseCards;
