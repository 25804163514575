import { HttpClient } from "../httpClient";

const defaultHttpError = 'Please try again. If the issue persists please contact Rebecca.'

export class HorseAPI {
    static async getHorses(setHorses: Function, setError: Function, includeBoarders?: boolean) {
        let queryParams = {}
        if (includeBoarders) {
            queryParams = { includeBoarders }
        }
        try {
            const result = await HttpClient.get('/horses', {}, queryParams);
            setHorses(result);
          } catch (error) {
             setError(defaultHttpError)
          }
    }
}