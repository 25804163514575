import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Person, RiderAndLesson, RiderLesson } from '../../types/People';
import EditAddVolunteerInfo from './EditAddVolunteerInfo';
import AddLessons from '../Lessons/AddLessons';
import { Lesson } from '../../types/Lessons';
import { HttpClient } from '../../helpers/httpClient';
import { formatTime } from '../../helpers/utils';
import BasicModal from '../Modals/BasicModal';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

function EditPerson(props: {
  person: RiderAndLesson;
  lessons: Lesson[];
  onSubmit: Function;
  onClose: () => void;
  show: boolean;
  mode: 'edit' | 'add' | 'editVolunteer' | 'addVolunteer';
  setErrorMessage: Function;
}) {
  const [validated, setValidated] = useState(false);
  const [person, setPerson] = useState<Person>(props.person);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [riderLessons, setRiderLessons] = useState<RiderLesson[]>([]);

  const handleSubmit = async () => {
    setButtonsDisabled(true);
    setValidated(true);

    const form: any = document.getElementById('personForm');
    try {
      if (form.checkValidity()) {
        const result = await props.onSubmit({
          ...person,
          rider: props.mode === 'add' ? true : person.rider,
          volunteer: props.mode === 'addVolunteer' ? true : person.volunteer,
        });
        console.log(result);

        if (riderLessons.length > 0) {
          Promise.allSettled(
            riderLessons.map((riderLesson) =>
              HttpClient.post(`/riders/${person.id}/lessons/${riderLesson.lessonId}`, {})
            )
          ).then((result) => {
            console.log(riderLessons);
            let failedLessons = [];
            for (let i = 0; i < result.length; i++) {
              if (result[i].status == 'rejected') {
                failedLessons.push(
                  `${riderLessons[i].activeDays.join(', ')} at ${formatTime(
                    riderLessons[i].lessonTime
                  )}`
                );
              }
            }

            if (failedLessons.length > 0) {
              props.setErrorMessage(`Failed to add rider to lessons: ${failedLessons.join(', ')}`);
            }

            props.onClose();
          });
        } else {
          props.onClose();
        }
      }
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    console.log(name, newValue);

    setPerson((prevState: Person) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <BasicModal
      onClose={props.onClose}
      open={props.show}
      title='Update Person'
      submitButton={{
        color: 'success',
        text: props.mode === 'edit' || props.mode === 'editVolunteer' ? 'Update' : 'Add',
        onClick: async (event) => {
          await handleSubmit();
        },
      }}
      closeButton
    >
      {props.mode === 'edit' || props.mode === 'add' ? (
        <form id='personForm'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label='First Name'
                required
                type='text'
                value={person.firstName}
                placeholder='First Name'
                name='firstName'
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Last Name'
                required
                type='text'
                value={person.lastName}
                placeholder='Last Name'
                name='lastName'
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Start Date'
                type='date'
                value={person.startDate}
                name='startDate'
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {person.rider || props.mode === 'add' ? (
            <Grid container spacing={2} marginTop='0.5em'>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='staff' />}
                  label='This person is an employee'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='schooling' />}
                  label='This person can do schooling rides'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={<Checkbox onChange={handleInputChange} name='eval' />}
                  label='This person is an evaluation'
                />
              </Grid>
            </Grid>
          ) : null}
          {props.mode === 'add' ? (
            <AddLessons
              riderLessons={riderLessons}
              setRiderLessons={setRiderLessons}
              lessons={props.lessons}
            />
          ) : null}
        </form>
      ) : (
        <form id='personForm'>
          <EditAddVolunteerInfo
            level={person.volunteerLevel == 0 ? '' : person.volunteerLevel}
            setLevel={(level: number) => {
              setPerson({
                ...person,
                volunteerLevel: level,
              });
            }}
          />
        </form>
      )}
    </BasicModal>
  );
}

export default EditPerson;
