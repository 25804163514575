import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import { Horse, horseType, owners } from '../../types/Horses';
import ErrorBanner from '../../components/ErrorBanner';
import { FormSubmitButton } from '../../components/SubmitButton';
import { Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import HorseForm from '../../components/Horses/HorseForm';

function AddHorse() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [horse, setHorse] = useState<Horse>({
    barnName: '',
    showName: '',
    birthDate: '',
    owner: '',
    startDate: '',
    type: '',
    hasPhoto: false,
    daysOff: [],
  });

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setErrorMessage('');

    try {
      if (form.checkValidity()) {
        const result = await HttpClient.post('/horses', horse);
        navigate('/horses');
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Horses
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding horse' errorMessage={errorMessage} />
      <form onSubmit={handleSubmit}>
        <HorseForm horse={horse} setHorse={setHorse} showNotes />
        <FormSubmitButton />
      </form>
    </Container>
  );
}

export default AddHorse;
