import { Avatar, Box, Input, Typography } from '@mui/material';
import LoadingSpinner from '../../images/LoadingSpinner';
import { useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';

const maxFileSize = 10 * 1024 * 1024;

function HorseAvatar(props: { horseId: string; setErrorMessage: (message: string) => void }) {
  const { horseId, setErrorMessage } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(
    `https://horse-photos-bucket.s3.amazonaws.com/${horseId}.jpeg`
  );

  const handleError = () => {
    setImgSrc('/images/default.jpeg');
  };

  const handleFileChange = async (event: any) => {
    const errorMessage =
      'Unable to upload photo. Please try again and if the issue persists contact Rebecca.';
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxFileSize) {
        setErrorMessage('The file size exceeds 10 MB. Please select a smaller file.');
        return;
      }
      setIsPhotoLoading(true);
      setImgSrc(`/images/default.jpeg`);
      setErrorMessage('');

      try {
        let response: { url: string } = await HttpClient.put(`horses/${horseId}/photo`, {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        });

        const renamedFile = new File([file], `${horseId}.jpeg`, { type: file.type });
        const uploadResponse = await fetch(response.url, {
          method: 'PUT',
          body: renamedFile,
        });

        console.log(uploadResponse);
        setIsPhotoLoading(false);

        if (uploadResponse.ok) {
          setImgSrc(`https://horse-photos-bucket.s3.amazonaws.com/${horseId}.jpeg`);
        } else {
          setErrorMessage(errorMessage);
        }
      } catch {
        setIsPhotoLoading(false);
        setImgSrc(`https://horse-photos-bucket.s3.amazonaws.com/${horseId}.jpeg`);
        setErrorMessage(errorMessage);
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        padding: '1em',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Avatar Image */}
      <Avatar
        src={imgSrc}
        onError={handleError}
        alt='Profile'
        sx={{
          width: '15rem',
          height: '15rem',
        }}
      />

      {/* Overlay with "Edit" text */}
      {isHovering && (
        <Box
          onClick={() => document.getElementById('file-input')!.click()}
          sx={{
            position: 'absolute',
            top: '1em',
            right: 0,
            bottom: 0,
            left: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            padding: '1em',
            width: '15rem',
            height: '15rem',
          }}
        >
          <Typography variant='h6'>Edit</Typography>
        </Box>
      )}
      {isPhotoLoading && (
        <Box
          onClick={() => document.getElementById('file-input')!.click()}
          sx={{
            position: 'absolute',
            top: '1em',
            right: 0,
            bottom: 0,
            left: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            padding: '1em',
            width: '15rem',
            height: '15rem',
          }}
        >
          <LoadingSpinner />
        </Box>
      )}

      <Input
        id='file-input'
        type='file'
        inputProps={{ accept: 'image/*' }}
        sx={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </Box>
  );
}

export default HorseAvatar;
