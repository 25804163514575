export enum FeedType {
  Hay = 'Hay',
  BeetPulp = 'Beet Pulp',
  Cubes = 'Cubes',
  Meds = 'Meds',
  Grain = 'Grain',
  Supplements = 'Supplements',
  Other = 'Other',
  None = '',
}

export enum FeedTime {
  AM = 'AM',
  PM = 'PM',
  Noon = 'Noon',
  Night = 'Night',
  None = '',
}

export const horseType: { [key: number]: string } = {
  1: 'Lesson',
  2: 'Boarder',
  3: 'Lesson & Boarder',
};

export const owners: { [key: string]: string } = {
  '6a1f2d9b-ff4b-4d79-9a94-b7c9ff52b2de': 'WELCA',
  'a9a91fa1-e646-4803-810f-36540bdcf49c': 'Little Bits',
  'e0e0a62d-468f-4151-a602-ae9e06c98c90': 'Boarder',
};

export type HorseFeed = {
  id?: string;
  horse: string;
  feedType: FeedType;
  subType?: string;
  feedTime?: FeedTime;
  amount: string;
  details: string;
};

export type Horse = {
  id?: string;
  barnName: string;
  showName?: string;
  birthDate: string;
  owner: string;
  startDate?: string;
  type: number | string;
  currentLameness?: number;
  feed?: HorseFeed[];
  lameness?: HorseLameness[];
  farrier?: HorseFarrier[];
  saddles?: HorseSaddle[];
  hasPhoto: boolean;
  daysOff: string[];
  notes?: string;
};

export enum AffectedPart {
  FrontRight = 'Front Right',
  FrontLeft = 'Front Left',
  BackRight = 'Back Right',
  BackLeft = 'Back Left',
  MultipleLegs = 'Multiple Legs',
  Other = 'Other',
  Unknown = 'Unknown',
  None = '',
}

export type HorseLameness = {
  id?: string;
  horse: string;
  horseName: string;
  affectedPart: AffectedPart;
  notes?: string;
  startDate: string;
  endDate?: string;
  checkDate?: string;
};

export type HorseFarrier = {
  id?: string;
  horse: string;
  type: string;
  appointmentDate: string;
  notes?: string;
};

export type Saddle = {
  id: string;
  saddle: string;
  smallSaddle: boolean;
};

export type Pad =
  | 'Ogilvy'
  | 'Wool'
  | 'Lamicell'
  | 'Prolite'
  | 'Half pad'
  | 'Riser'
  | 'Thinline'
  | 'Foam';
export type HorseSaddle = Saddle & {
  id: string;
  horse: string;
  saddleId: string;
  padsArray: Pad[];
  pads: string;
  additions: string;
  notes: string;
  rank: number;
  priorityFit: boolean;
};
