import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MouseEventHandler, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type NavigatableDropdownOption = {
  display: string;
  location: string;
};

function SearchWithButtonsAndToggle(props: {
  label: string;
  searchValue: string;
  setSearchValue: Function;
  dropdownOptions: NavigatableDropdownOption[];
  radioValue: string | null;
  setRadioValue: Function;
  radioOptions: { display: string; value: string }[];
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchValue(event.target.value);
  };
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container alignItems='center' columnSpacing={2} rowSpacing={1} marginBottom='1em'>
      <Grid item xs={12} sm={3}>
        <ToggleButtonGroup
          value={props.radioValue}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, newFilter: string | null) => {
            props.setRadioValue(newFilter);
          }}
          aria-label='report type'
          fullWidth
          color='success'
        >
          {props.radioOptions.map((option, index) => (
            <ToggleButton key={index} value={option.value}>
              {option.display}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={props.label}
          value={props.searchValue}
          onChange={handleSearchChange}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ButtonGroup variant='contained' color='success' ref={anchorRef} fullWidth>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='menu'
            onClick={handleToggle}
          >
            Manage <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {props.dropdownOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => {
                          navigate(option.location);
                          setOpen(false);
                        }}
                      >
                        {option.display}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

export default SearchWithButtonsAndToggle;
