import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import ConfirmDeletionModal from '../../components/Modals/ConfirmDeletionModal';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Lesson } from '../../types/Lessons';
import { Contact, RiderAndLesson } from '../../types/People';
import EditPerson from '../../components/Riders/EditPerson';
import DisplayPerson from '../../components/Riders/DisplayPerson';
import DisplayRiderLessons from '../../components/Lessons/DisplayRiderLessons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import AddLessonModal from '../../components/Riders/AddLessonModal';
import DisplayRiderContacts from '../../components/Riders/DisplayRiderContacts';
import AddEmergencyContactModal from '../../components/Riders/AddEmergencyContactModal';
import RemoveFromLessonModal from '../../components/Modals/RemoveFromLessonModal';

import dayjs from 'dayjs';

function ViewEditPerson() {
  const params = useParams();
  const personId = params.person!;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mode, setMode] = useState<
    | 'view'
    | 'add'
    | 'edit'
    | 'delete'
    | 'editVolunteer'
    | 'addVolunteer'
    | 'deleteVolunteer'
    | 'deleteLesson'
    | 'deleteBoth'
    | 'deleteContact'
  >('view');
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [person, setPerson] = useState<RiderAndLesson>({
    id: '',
    firstName: '',
    lastName: '',
    rider: true,
    staff: false,
    schooling: false,
    eval: false,
    volunteer: false,
    volunteerLevel: '',
    lessonInfo: {
      currentLessons: [],
      pastLessons: [],
      futureLessons: [],
    },
  });
  const [itemToDelete, setItemToDelete] = useState<string>('');
  const [addingLesson, setAddingLesson] = useState<'' | 'current' | 'future'>('');
  const [addingContact, setAddingContact] = useState<boolean>(false);
  const [contactToEdit, setContactToEdit] = useState<Contact | undefined>(undefined);

  async function getPerson(personId: string) {
    try {
      const result: RiderAndLesson = await HttpClient.get(`/people/${personId}`);
      setPerson(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getLessons() {
    try {
      const result: Lesson[] = await HttpClient.get(
        '/lessons',
        {},
        {
          amount: 'currentAndNext',
        }
      );
      setLessons(result);
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  async function onSubmit(person: RiderAndLesson) {
    setButtonsDisabled(true);
    setErrorMessage('');
    try {
      const result = await HttpClient.put(`/people/${personId}`, person);
      await getPerson(personId);
      setMode('view');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  async function onDeleteContact(contactId: string) {
    setButtonsDisabled(true);
    setErrorMessage('');
    try {
      const result = await HttpClient.delete(`/people/${personId}/contacts/${contactId}`);
      console.log(result);
      await getPerson(personId);
      setMode('view');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setButtonsDisabled(false);
  }

  useEffect(() => {
    getPerson(personId);
    getLessons();
  }, [personId]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          View/Edit Person
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error' errorMessage={errorMessage} />
      <RemoveFromLessonModal
        show={mode === 'deleteLesson'}
        handleClose={() => {
          setItemToDelete('');
        }}
        handleConfirm={() => {
          getPerson(personId);
          setMode('view');
        }}
        boardId={itemToDelete}
        leftClass={dayjs().format('YYYY-MM-DD')}
      />
      <ConfirmDeletionModal
        show={
          mode === 'delete' ||
          mode == 'deleteVolunteer' ||
          mode === 'deleteBoth' ||
          mode === 'deleteContact'
        }
        handleClose={() => {
          if (mode == 'deleteLesson' || mode === 'deleteContact') {
            setItemToDelete('');
          }

          setMode('view');
        }}
        handleConfirm={() => {
          if (mode === 'delete') {
            if (person.lessonInfo.currentLessons.length > 0) {
              setErrorMessage('Please remove rider from lessons before deleting.');
              setMode('view');
              return;
            }
            onSubmit({ ...person, rider: false });
          } else if (mode === 'deleteVolunteer') {
            onSubmit({ ...person, volunteer: false });
          } else if (mode === 'deleteBoth') {
            if (person.lessonInfo.currentLessons.length > 0) {
              setErrorMessage('Please remove rider from lessons before deleting.');
              setMode('view');
              return;
            }
            onSubmit({ ...person, rider: false, volunteer: false });
          } else if (mode === 'deleteContact') {
            onDeleteContact(itemToDelete);
          }
        }}
        deletionText={
          mode == 'deleteContact'
            ? 'Are you sure you want to delete the emergency contact?'
            : mode == 'deleteLesson'
            ? 'Are you sure you want to delete the lesson?'
            : mode == 'deleteVolunteer'
            ? 'Are you sure you want to delete the volunteer?'
            : 'Are you sure you want to delete the rider?'
        }
      />
      <Grid container spacing={2} marginTop='0.5em'>
        <Grid item xs={6}>
          <Box>
            <Typography variant='h4'>Basic Info</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} container alignItems='center'>
          <Box sx={{ marginLeft: 'auto' }}>
            <ButtonGroup variant='contained' color='success' ref={anchorRef}>
              <Button
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='menu'
                onClick={handleToggle}
              >
                Actions <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          </Box>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu' autoFocusItem>
                      <MenuItem
                        onClick={() => {
                          setMode('edit');
                          setOpen(false);
                        }}
                      >
                        Edit basic info
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setMode(person.volunteer ? 'editVolunteer' : 'addVolunteer');
                          setOpen(false);
                        }}
                      >
                        {person.volunteer ? 'Edit volunteer info' : 'Add volunteer info'}
                      </MenuItem>
                      {!person.rider ? (
                        <MenuItem
                          onClick={() => {
                            setMode('add');
                            setOpen(false);
                          }}
                        >
                          Add rider info
                        </MenuItem>
                      ) : null}
                      {person.volunteer ? (
                        <MenuItem
                          onClick={() => {
                            setMode('deleteVolunteer');
                            setOpen(false);
                          }}
                        >
                          Remove from volunteer list
                        </MenuItem>
                      ) : null}
                      {person.rider ? (
                        <MenuItem
                          onClick={() => {
                            setMode('delete');
                            setOpen(false);
                          }}
                        >
                          Remove from rider list
                        </MenuItem>
                      ) : null}
                      {person.rider && person.volunteer ? (
                        <MenuItem
                          onClick={() => {
                            setMode('deleteBoth');
                            setOpen(false);
                          }}
                        >
                          Remove from rider and volunteer list
                        </MenuItem>
                      ) : null}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      {mode !== 'view' &&
      mode !== 'delete' &&
      mode !== 'deleteLesson' &&
      mode != 'deleteVolunteer' &&
      mode != 'deleteBoth' &&
      mode != 'deleteContact' ? (
        <EditPerson
          person={person}
          lessons={lessons}
          onSubmit={onSubmit}
          setErrorMessage={setErrorMessage}
          mode={mode}
          show={true}
          onClose={() => {
            setMode('view');
            getPerson(personId);
          }}
        />
      ) : (
        <>
          <DisplayPerson person={person} />
          <DisplayRiderContacts
            title='Emergency Contacts'
            riderContacts={person.emergencyContacts}
            onDeleteClick={(lessonId: string) => {
              setItemToDelete(lessonId);
              setMode('deleteContact');
            }}
            buttonsDisabled={buttonsDisabled}
            onEditClick={(contactToEdit: Contact) => {
              setContactToEdit(contactToEdit);
              setAddingContact(true);
            }}
          />
          {!addingContact ? (
            <Grid container justifyContent='center' marginTop={'1em'}>
              <Grid item>
                <Button
                  variant='contained'
                  color='inherit'
                  id='add-horsemanship-person'
                  onClick={() => {
                    setAddingContact(true);
                  }}
                >
                  <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Emergency
                  Contact
                </Button>
              </Grid>
            </Grid>
          ) : (
            <AddEmergencyContactModal
              personId={personId}
              show={true}
              default={contactToEdit}
              handleConfirm={async () => {
                await getPerson(personId);
              }}
              handleClose={() => {
                setAddingContact(false);
                setContactToEdit(undefined);
              }}
            />
          )}
        </>
      )}
      {person.volunteer || Number(person.volunteerLevel) > 0 ? (
        <>
          <Grid container marginTop='1em' marginBottom='1em'>
            <Grid item xs={12}>
              <Typography variant='h4'>Volunteer Info</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} paddingBottom={'1em'}>
              <Typography fontWeight={'bold'}>Active volunteer</Typography>
              <Typography>{person.volunteer ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} paddingBottom={'1em'}>
              <Typography fontWeight={'bold'}>Level</Typography>
              <Typography>{person.volunteerLevel}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} paddingBottom={'1em'}>
              <Typography fontWeight={'bold'}>Number of hours worked</Typography>
              <Typography>TBD</Typography>
            </Grid>
          </Grid>
        </>
      ) : null}

      {person.rider ? (
        <>
          <DisplayRiderLessons
            title='Current Lessons'
            riderLessons={person.lessonInfo.currentLessons}
            onDeleteClick={(boardId: string) => {
              setItemToDelete(boardId);
              setMode('deleteLesson');
            }}
            buttonsDisabled={buttonsDisabled}
            displayBlank
          />
          {addingLesson ? (
            <AddLessonModal
              isFutureLesson={addingLesson === 'future'}
              personId={personId}
              lessons={lessons.filter((l: Lesson) => {
                if (addingLesson === 'future') {
                  return !l.currentLesson;
                }

                return l.currentLesson;
              })}
              show={true}
              handleConfirm={async () => {
                await getPerson(personId);
              }}
              handleClose={() => {
                setAddingLesson('');
              }}
            />
          ) : null}
          {!addingLesson ? (
            <Grid container justifyContent='center' marginTop={'1em'}>
              <Grid item>
                <Button
                  variant='contained'
                  color='inherit'
                  id='add-horsemanship-person'
                  onClick={() => {
                    setAddingLesson('current');
                  }}
                >
                  <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Lesson
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <DisplayRiderLessons
            title='Future Lessons'
            riderLessons={person.lessonInfo.futureLessons}
            onDeleteClick={(lessonId: string) => {
              setItemToDelete(lessonId);
              setMode('deleteLesson');
            }}
            buttonsDisabled={buttonsDisabled}
            displayDuration
            displayBlank
          />
          {!addingLesson ? (
            <Grid container justifyContent='center' marginTop={'1em'}>
              <Grid item>
                <Button
                  variant='contained'
                  color='inherit'
                  id='add-horsemanship-person'
                  onClick={() => {
                    setAddingLesson('future');
                  }}
                >
                  <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Lesson
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : null}
      {person.rider || (person.lessonInfo && person.lessonInfo.pastLessons.length > 0) ? (
        <DisplayRiderLessons
          title='Past Lessons'
          riderLessons={person.lessonInfo.pastLessons}
          onDeleteClick={(lessonId: string) => {
            setItemToDelete(lessonId);
            setMode('deleteLesson');
          }}
          buttonsDisabled={buttonsDisabled}
          displayDuration
        />
      ) : null}
    </Container>
  );
}

export default ViewEditPerson;
