import { useNavigate } from 'react-router-dom';
import { Horse, horseType } from '../../types/Horses';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useState } from 'react';

function HorseCard(props: { horse: Horse }) {
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(
    `https://horse-photos-bucket.s3.amazonaws.com/${props.horse.id}.jpeg`
  );

  const handleError = () => {
    // Fallback to the default image if error occurs (e.g., 404)
    setImgSrc('/images/default.jpeg');
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        onClick={() => {
          navigate(`/horses/${props.horse.id}`);
        }}
      >
        <CardMedia
          component='img'
          height='300'
          image={imgSrc}
          onError={handleError}
          alt='default image'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {props.horse.barnName}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {horseType[Number(props.horse.type)]}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {props.horse.birthDate}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default HorseCard;
